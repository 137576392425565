const Links = [
	{
		url: 'menu',
		content: 'Menu',
	},
	{
		url: 'about',
		content: 'About',
	},
	{
		url: 'delivery',
		content: 'Delivery',
	},
	{
		url: 'contact',
		content: 'Contact',
	},
];

export default Links;
